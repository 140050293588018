import React, {Component} from 'react'
import {Link} from "../../plugins/gatsby-plugin-atollon";
import {Instagram, LinkedIn, YouTube, Logo} from "./icons";
import Newsletter from "./structured-text/blocks/newsletter";

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__logo'>
            <Link to='/' title='Bohm'>
              <Logo color='#B94A32'/>
            </Link>
          </div>
          <div className='footer__cols'>
            <div className='footer__col'>
              <ul>
                <li><Link to='/buy/'>Listings</Link></li>
                <li><Link to='/rent/'>Rentals</Link></li>
                <li><Link to='/journal/'>Journal</Link></li>
                <li><Link to='/about/'>About</Link></li>
                <li><Link to='/contact/'>Contact</Link></li>
              </ul>
            </div>
            <div className='footer__col'>
              <ul>
                <li>
                  <span>Call</span>
                  <Link to='tel:+441617061816'>+44 (0) 161 706 1816</Link>
                </li>
                <li>
                  <span>Email</span>
                  <Link to='mailto:hello@bohm.uk'>hello@bohm.uk</Link>
                </li>
                <li>
                  <Link to='/contact/'>82 King Street, Deansgate,<br/>Manchester, M2 4WQ.</Link>
                </li>
                <li>
                  <Link to='/privacy-policy'>Privacy Policy</Link><br/>
                  <Link to='/terms-and-conditions'>Terms & Conditions</Link>
                </li>
              </ul>
            </div>
            <div className='footer__col'>
              <span>Subscribe to our newsletter for exclusive <br/>updates on our properties, journal and <br/>industry insights.</span>
              <Newsletter/>
            </div>
            <div className='footer__col'>
              <ul className='footer__social'>
                <li><Link to='https://www.youtube.com/channel/UCKiq5yr1J78NVVlS1_lUKdA'><YouTube color={'#B94A32'}/></Link></li>
                <li><Link to='https://www.instagram.com/bohm.house/'><Instagram color={'#B94A32'}/></Link></li>
              </ul>
              <p>All rights reserved {new Date().getFullYear()}.<br/>Design by <Link
                to='https://atollon.com.au/real-estate-design-agency/'>Atollon</Link></p>
            </div>
          </div>
        </div>
        <div className='footer__inner'>
          <div className='footer__logos'>
            <svg width="150" height="53" viewBox="0 0 150 53" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M147.499 0.081543H2.50147C1.11995 0.081543 0 1.20022 0 2.58019V49.5829C0 50.9629 1.11995 52.0815 2.50147 52.0815H147.499C148.88 52.0815 150 50.9629 150 49.5829V2.58019C150 1.20022 148.88 0.081543 147.499 0.081543Z"
                fill="#B94A32"/>
              <path
                d="M67.9279 34.0447C65.2279 34.0447 64.147 31.8105 64.147 29.0739C64.147 26.3373 65.2279 24.103 67.9279 24.103C70.6279 24.103 71.7087 26.3505 71.7087 29.0739C71.7087 31.7973 70.6279 34.0447 67.9279 34.0447ZM67.9279 25.6057C66.7058 25.6057 66.4676 27.3464 66.4676 28.9901C66.4676 30.6339 66.6926 32.3746 67.9279 32.3746C69.1632 32.3746 69.3882 30.6471 69.3882 28.9901C69.3882 27.3332 69.1367 25.6057 67.9279 25.6057Z"
                fill="white"/>
              <path
                d="M81.6617 33.9038V28.598C81.6617 28.0207 81.322 27.5712 80.5631 27.5712C80.1837 27.5712 79.8617 27.6858 79.6058 27.937V33.9038H77.4131V28.5671C77.4131 27.9634 76.9896 27.5712 76.2308 27.5712C75.8381 27.5712 75.6131 27.6153 75.344 27.7122V33.9038H73.1514V26.756C73.994 26.377 74.894 26.1919 76.2175 26.1919C77.4661 26.1919 78.1852 26.5312 78.622 26.9499C79.0984 26.5444 79.8749 26.1919 81.1234 26.1919C82.9234 26.1919 83.8499 27.2583 83.8499 28.5936V33.8993H81.6573L81.6617 33.9038Z"
                fill="white"/>
              <path
                d="M88.2573 34.0447C86.9514 34.0447 86.0249 33.8067 85.5176 33.485V22.8867H87.7102V26.5223C88.0632 26.3549 88.4558 26.2403 89.1132 26.2403C90.8999 26.2403 92.1352 27.6725 92.1352 29.9067C92.1352 32.5596 90.7455 34.0491 88.2573 34.0491M88.4955 27.4918C88.1426 27.4918 87.9176 27.5491 87.7058 27.6593V32.6169C87.8735 32.6874 88.1117 32.7315 88.3808 32.7315C89.4926 32.7315 89.969 31.7488 89.969 30.0477C89.969 28.5715 89.6602 27.4918 88.4911 27.4918"
                fill="white"/>
              <path
                d="M96.7058 34.0444C94.5705 34.0444 93.5293 33.2423 93.5293 31.4179V26.3369H95.7219V31.5722C95.7219 32.1186 96.0175 32.5681 96.7631 32.5681C97.1558 32.5681 97.4381 32.5108 97.6764 32.3874V26.3369H99.869V33.4539C99.1675 33.8064 98.1264 34.0444 96.7058 34.0444Z"
                fill="white"/>
              <path
                d="M104.987 34.0447C102.697 34.0447 101.263 32.6962 101.263 30.1711C101.263 27.6461 102.64 26.2535 104.594 26.2535C105.044 26.2535 105.423 26.3108 105.706 26.3769V22.8823H107.885V33.4806C107.197 33.8464 106.297 34.0403 104.991 34.0403M105.706 27.5535C105.525 27.4962 105.326 27.4698 105.101 27.4698C103.893 27.4698 103.429 28.5098 103.429 30.0654C103.429 31.7091 103.919 32.6477 105.031 32.6477C105.326 32.6477 105.551 32.6213 105.706 32.5376V27.5535Z"
                fill="white"/>
              <path
                d="M111.313 34.0315C110.387 34.0315 109.557 33.7936 109.09 33.4851L109.65 32.097C109.932 32.3217 110.453 32.5332 111 32.5332C111.547 32.5332 111.94 32.1851 111.94 31.7048C111.94 31.198 111.671 30.9203 110.969 30.6383C109.58 30.0919 109.266 29.3207 109.266 28.5319C109.266 27.2407 110.281 26.2007 111.799 26.2007C112.743 26.2007 113.387 26.3814 113.978 26.7471L113.444 27.9942C113.219 27.8136 112.782 27.602 112.293 27.602C111.715 27.602 111.419 27.9546 111.419 28.3864C111.419 28.8932 111.799 29.1753 112.39 29.4264C113.85 30.0434 114.19 30.7617 114.19 31.6431C114.19 32.9607 113.109 34.0315 111.309 34.0315"
                fill="white"/>
              <path
                d="M124.05 33.9038V28.598C124.05 28.0207 123.71 27.5712 122.951 27.5712C122.572 27.5712 122.25 27.6858 121.994 27.937V33.9038H119.801V28.5671C119.801 27.9634 119.378 27.5712 118.619 27.5712C118.226 27.5712 118.001 27.6153 117.732 27.7122V33.9038H115.54V26.756C116.382 26.377 117.282 26.1919 118.606 26.1919C119.854 26.1919 120.573 26.5312 121.01 26.9499C121.487 26.5444 122.263 26.1919 123.512 26.1919C125.312 26.1919 126.238 27.2583 126.238 28.5936V33.8993H124.045L124.05 33.9038Z"
                fill="white"/>
              <path
                d="M130.87 34.0448C128.678 34.0448 127.553 33.2295 127.553 31.6563C127.553 29.7041 129.507 29.1312 131.784 28.9197V28.5671C131.784 27.7827 131.25 27.558 130.447 27.558C129.714 27.558 128.969 27.796 128.479 28.0207L128.003 26.7692C128.537 26.5444 129.52 26.1919 130.857 26.1919C132.573 26.1919 133.835 26.9058 133.835 28.9594V33.4807C133.204 33.8156 132.176 34.0404 130.87 34.0404M131.784 29.9288C130.46 30.0566 129.604 30.3651 129.604 31.5726C129.604 32.4583 130.112 32.8373 130.981 32.8373C131.303 32.8373 131.585 32.78 131.784 32.6831V29.9332V29.9288Z"
                fill="white"/>
              <path
                d="M139.743 33.9038V28.6112C139.743 28.078 139.504 27.558 138.618 27.558C138.181 27.558 137.943 27.6285 137.691 27.7122V33.9038H135.499V26.756C136.257 26.4166 137.325 26.1919 138.675 26.1919C141.079 26.1919 141.935 27.2187 141.935 28.5936V33.8993H139.743V33.9038Z"
                fill="white"/>
              <path d="M67.9413 11.6888V19.8193H65.6516V11.6888H63.9619V10.1597H69.6134V11.6888H67.9413Z" fill="white"/>
              <path
                d="M75.1411 19.8021V14.5008C75.1411 13.9103 74.8764 13.4343 74.0161 13.4343C73.6632 13.4343 73.3852 13.4916 73.0896 13.6458V19.8021H70.897V10.1865H73.0896V12.4516C73.597 12.2269 74.1308 12.0991 74.7176 12.0991C76.6146 12.0991 77.3337 13.1964 77.3337 14.4567V19.7977H75.1411V19.8021Z"
                fill="white"/>
              <path
                d="M80.806 16.8006C80.9604 17.9111 81.5207 18.4576 82.6325 18.4576C83.5192 18.4576 84.1942 18.2593 84.6001 18.0522L85.0898 19.4138C84.4986 19.7091 83.656 19.9603 82.3766 19.9603C80.056 19.9603 78.7236 18.4311 78.7236 16.0162C78.7236 13.6013 79.9589 12.1118 82.1383 12.1118C84.3177 12.1118 85.4427 13.6013 85.3457 16.1572L80.806 16.805V16.8006ZM82.0589 13.403C81.1589 13.403 80.5986 14.1742 80.6692 15.7342L83.3119 15.342C83.3119 13.9935 82.8619 13.403 82.0633 13.403"
                fill="white"/>
              <path
                d="M92.797 16.2106L92.4882 16.1841V19.8197H90.2515V10.5787C90.9397 10.2438 91.9676 10.019 93.1897 10.019C95.872 10.019 96.9132 11.3807 96.9132 13.1082C96.9132 14.8357 95.7044 16.4794 92.797 16.2106ZM93.1765 11.3102C92.8676 11.3102 92.6162 11.3675 92.4882 11.4204V14.8753L92.797 14.8885C94.2309 14.959 94.7515 14.3553 94.7515 13.0906C94.7515 11.8258 94.3323 11.3102 93.1765 11.3102Z"
                fill="white"/>
              <path
                d="M101.881 13.681C101.347 13.4563 100.782 13.4695 100.421 13.6369V19.8153H98.228V12.6675C99.0707 12.262 100.253 12.0373 102.291 12.1342L101.885 13.6766L101.881 13.681Z"
                fill="white"/>
              <path
                d="M106.085 19.9603C103.893 19.9603 102.741 18.2901 102.741 16.0294C102.741 13.7688 103.893 12.1118 106.072 12.1118C108.251 12.1118 109.429 13.7688 109.429 16.0294C109.429 18.2901 108.278 19.9603 106.085 19.9603ZM106.085 13.4735C105.115 13.4735 104.921 14.6545 104.921 15.9986C104.921 17.3427 105.132 18.4708 106.085 18.4708C107.038 18.4708 107.25 17.3603 107.25 15.9986C107.25 14.6369 107.052 13.4735 106.085 13.4735Z"
                fill="white"/>
              <path
                d="M114.111 19.9162C113.647 19.9162 113.237 19.859 113.012 19.7885V22.8335H110.819V12.6671C111.468 12.3454 112.368 12.103 113.713 12.103C115.977 12.103 117.437 13.4383 117.437 15.9766C117.437 18.5149 116.074 19.9074 114.106 19.9074M113.683 13.3942C113.387 13.3942 113.193 13.4383 113.008 13.5088V18.5193C113.149 18.5766 113.343 18.6162 113.586 18.6162C114.75 18.6162 115.271 17.704 115.271 16.003C115.271 14.302 114.847 13.3898 113.683 13.3898"
                fill="white"/>
              <path
                d="M120.649 16.8006C120.803 17.9111 121.363 18.4576 122.475 18.4576C123.362 18.4576 124.037 18.2593 124.443 18.0522L124.937 19.4138C124.346 19.7091 123.503 19.9603 122.224 19.9603C119.903 19.9603 118.566 18.4311 118.566 16.0162C118.566 13.6013 119.806 12.1118 121.981 12.1118C124.156 12.1118 125.286 13.6013 125.188 16.1572L120.649 16.805V16.8006ZM121.902 13.403C121.002 13.403 120.441 14.1742 120.512 15.7342L123.155 15.342C123.155 13.9935 122.705 13.403 121.906 13.403"
                fill="white"/>
              <path
                d="M130.235 13.681C129.702 13.4563 129.137 13.4695 128.775 13.6369V19.8153H126.583V12.6675C127.425 12.262 128.608 12.0373 130.646 12.1342L130.24 13.6766L130.235 13.681Z"
                fill="white"/>
              <path
                d="M134.356 19.9297C132.768 19.9297 132.079 18.9074 132.079 17.7396V13.6677H131.25V12.2487H132.079V10.6358L134.272 10.0718V12.2487H135.437V13.6677H134.272V17.5721C134.272 18.1494 134.484 18.4579 134.974 18.4579C135.154 18.4579 135.256 18.4314 135.437 18.3742V19.7226C135.256 19.8064 134.859 19.9342 134.356 19.9342"
                fill="white"/>
              <path
                d="M139.668 22.838H137.36L138.569 19.8194L136.107 12.2529H138.428L139.469 16.6333C139.624 17.2811 139.624 17.8275 139.624 17.8275H139.654C139.654 17.8275 139.699 17.2811 139.866 16.6333L141.018 12.2529H143.069L139.668 22.838Z"
                fill="white"/>
              <path
                d="M58.9898 40.7077L55.8001 36.0321H34.9148C33.6707 36.0321 32.6869 35.5254 32.2236 34.6352C31.756 33.7538 31.8884 32.6565 32.5898 31.6298L42.7016 16.8098L35.9869 6.96061C34.4384 4.69112 31.9016 4.68671 30.3531 6.96061L7.32806 40.7121C5.77953 42.9816 6.76335 44.8413 9.51188 44.8413H56.8016C59.5545 44.8413 60.5384 42.9816 58.9898 40.7121"
                fill="white"/>
              <path
                d="M60.2822 32.0617L48.8513 15.2939C48.3969 14.6285 47.7527 14.2495 47.0777 14.2495C46.9322 14.2495 46.791 14.276 46.6498 14.3068C46.1336 14.4302 45.666 14.7607 45.3042 15.2895L43.4822 17.96L33.8601 32.0617C33.388 32.7536 33.2866 33.4322 33.5733 33.9743C33.8601 34.5163 34.4777 34.816 35.316 34.816H58.8219C59.5366 34.816 60.0792 34.5868 60.4057 34.1858C60.463 34.1153 60.5248 34.0536 60.5689 33.9743C60.8557 33.4322 60.7542 32.7536 60.2822 32.0617ZM47.0777 15.1309C47.4616 15.1309 47.8322 15.3644 48.1233 15.7919L59.5542 32.5597C59.8277 32.9607 59.916 33.3265 59.788 33.5644C59.6645 33.8024 59.3116 33.939 58.8263 33.939H35.3204C34.8351 33.939 34.4822 33.8024 34.3586 33.5688C34.2351 33.3309 34.3189 32.9651 34.5925 32.5641L44.016 18.7488L46.0322 15.7919C46.3233 15.3644 46.6939 15.1309 47.0777 15.1309Z"
                fill="white"/>
            </svg>
            <svg width="104" height="53" viewBox="0 0 104 53" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.9192 42.8479L13.3321 41.1223H9.95678L9.36977 42.8479H7.98877L11.1233 34.25H12.1694L15.3039 42.8479H13.9229H13.9192ZM11.6727 36.1951L10.3406 40.0097H12.9709L11.6727 36.1951Z"
                fill="#B94A32"/>
              <path
                d="M19.3379 39.4687H17.3925V42.8518H16.0942V34.2539H19.3379C21.0086 34.2539 22.0773 35.3665 22.0773 36.8613C22.0773 38.3561 21.0086 39.4687 19.3379 39.4687ZM19.2664 35.4233H17.3925V38.2842H19.2664C20.1657 38.2842 20.7791 37.7657 20.7791 36.8613C20.7791 35.9568 20.1657 35.4233 19.2664 35.4233Z"
                fill="#B94A32"/>
              <path
                d="M26.4683 39.4687H24.5228V42.8518H23.2246V34.2539H26.4683C28.139 34.2539 29.2077 35.3665 29.2077 36.8613C29.2077 38.3561 28.139 39.4687 26.4683 39.4687ZM26.3968 35.4233H24.5228V38.2842H26.3968C27.2961 38.2842 27.9095 37.7657 27.9095 36.8613C27.9095 35.9568 27.2961 35.4233 26.3968 35.4233Z"
                fill="#B94A32"/>
              <path
                d="M35.078 42.8479L33.2755 39.2263H31.6537V42.8479H30.3555V34.25H33.6706C35.2924 34.25 36.3235 35.302 36.3235 36.7628C36.3235 37.9965 35.5784 38.7798 34.6189 39.0447L36.5869 42.8479H35.0742H35.078ZM33.5765 35.4231H31.6537V38.1138H33.5765C34.442 38.1138 35.029 37.618 35.029 36.7741C35.029 35.9302 34.442 35.4231 33.5765 35.4231Z"
                fill="#B94A32"/>
              <path
                d="M42.8257 42.004C42.2387 42.5944 41.4824 42.9198 40.568 42.9198C39.6536 42.9198 38.9123 42.5944 38.3215 42.004C37.4824 41.1601 37.4937 40.2065 37.4937 38.549C37.4937 36.8914 37.4824 35.9416 38.3215 35.0939C38.9085 34.4998 39.6536 34.1743 40.568 34.1743C41.4824 34.1743 42.2387 34.4998 42.8257 35.0939C43.6649 35.9378 43.6423 36.8914 43.6423 38.549C43.6423 40.2065 43.6649 41.1563 42.8257 42.004ZM41.8398 35.881C41.5388 35.5556 41.0722 35.3512 40.568 35.3512C40.0637 35.3512 39.5934 35.5556 39.2961 35.881C38.8747 36.3389 38.7919 36.8233 38.7919 38.549C38.7919 40.2746 38.8747 40.759 39.2961 41.2169C39.5971 41.5423 40.0637 41.7467 40.568 41.7467C41.0722 41.7467 41.5426 41.5423 41.8398 41.2169C42.2613 40.759 42.3441 40.2746 42.3441 38.549C42.3441 36.8233 42.2613 36.3389 41.8398 35.881Z"
                fill="#B94A32"/>
              <path
                d="M47.7857 42.8479H46.7735L43.9663 34.25H45.3097L47.2777 40.5054L49.2495 34.25H50.6192L47.7857 42.8479Z"
                fill="#B94A32"/>
              <path
                d="M51.3755 42.8479V34.25H56.8393V35.4231H52.6699V37.9359H56.2259V39.0939H52.6699V41.6786H56.8393V42.8479H51.3755Z"
                fill="#B94A32"/>
              <path
                d="M63.4055 42.0381C62.8637 42.583 62.0847 42.8479 61.1967 42.8479H58.1938V34.25H61.1967C62.0847 34.25 62.8674 34.5149 63.4055 35.0598C64.3312 35.9908 64.271 37.2472 64.271 38.4884C64.271 39.7297 64.3312 41.1071 63.4055 42.0381ZM62.5175 35.9983C62.1562 35.601 61.6783 35.4193 61.065 35.4193H59.4921V41.6748H61.065C61.6783 41.6748 62.16 41.4931 62.5175 41.0958C62.9615 40.6 62.9728 39.6691 62.9728 38.4884C62.9728 37.3077 62.9615 36.4979 62.5175 36.0021"
                fill="#B94A32"/>
              <path
                d="M70.3331 42.9197C69.4451 42.9197 68.6774 42.5942 68.0866 42.0001C67.2475 41.1562 67.2588 40.2026 67.2588 38.5451C67.2588 36.8875 67.2475 35.9377 68.0866 35.09C68.6737 34.4996 69.4451 34.1704 70.3331 34.1704C71.906 34.1704 73.0838 35.1013 73.3811 36.8156H72.0603C71.8571 35.9452 71.2814 35.3435 70.3331 35.3435C69.8289 35.3435 69.3736 35.5365 69.0725 35.862C68.6511 36.3199 68.557 36.8156 68.557 38.5413C68.557 40.2669 68.6548 40.7627 69.0725 41.2205C69.3736 41.546 69.8289 41.739 70.3331 41.739C71.2814 41.739 71.8684 41.1373 72.0754 40.2669H73.3849C73.0989 41.9812 71.8834 42.9121 70.3369 42.9121"
                fill="#B94A32"/>
              <path
                d="M79.7139 42.004C79.1269 42.5944 78.3706 42.9198 77.4562 42.9198C76.5418 42.9198 75.8005 42.5944 75.2097 42.004C74.3706 41.1601 74.3818 40.2065 74.3818 38.549C74.3818 36.8914 74.3706 35.9416 75.2097 35.0939C75.8005 34.4998 76.5418 34.1743 77.4562 34.1743C78.3706 34.1743 79.1231 34.4998 79.7139 35.0939C80.5531 35.9378 80.5305 36.8914 80.5305 38.549C80.5305 40.2065 80.5531 41.1563 79.7139 42.004ZM78.728 35.881C78.427 35.5556 77.9604 35.3512 77.4562 35.3512C76.9519 35.3512 76.4816 35.5556 76.1843 35.881C75.7628 36.3389 75.6801 36.8233 75.6801 38.549C75.6801 40.2746 75.7628 40.759 76.1843 41.2169C76.4853 41.5423 76.9519 41.7467 77.4562 41.7467C77.9604 41.7467 78.427 41.5423 78.728 41.2169C79.1495 40.759 79.2323 40.2746 79.2323 38.549C79.2323 36.8233 79.1495 36.3389 78.728 35.881Z"
                fill="#B94A32"/>
              <path
                d="M87.2439 42.0381C86.702 42.583 85.9231 42.8479 85.035 42.8479H82.0322V34.25H85.035C85.9231 34.25 86.702 34.5149 87.2439 35.0598C88.1696 35.9908 88.1094 37.2472 88.1094 38.4884C88.1094 39.7297 88.1696 41.1071 87.2439 42.0381ZM86.3558 35.9983C85.9946 35.601 85.5167 35.4193 84.9033 35.4193H83.3304V41.6748H84.9033C85.5167 41.6748 85.9984 41.4931 86.3558 41.0958C86.7999 40.6 86.8112 39.6691 86.8112 38.4884C86.8112 37.3077 86.7999 36.4979 86.3558 36.0021"
                fill="#B94A32"/>
              <path
                d="M89.6069 42.8479V34.25H95.0707V35.4231H90.9051V37.9359H94.4574V39.0939H90.9051V41.6786H95.0707V42.8479H89.6069Z"
                fill="#B94A32"/>
              <path d="M10.2315 47.2073V52.0323H9.39235V47.2073H7.86084V46.4429H11.763V47.2073H10.2315Z"
                    fill="#B94A32"/>
              <path
                d="M15.7143 52.0323L14.544 49.6784H13.4904V52.0323H12.6475V46.4429H14.8036C15.8572 46.4429 16.527 47.124 16.527 48.0739C16.527 48.8762 16.0454 49.3871 15.4207 49.5573L16.7001 52.0285H15.7143V52.0323ZM14.7397 47.2073H13.4904V48.9556H14.7397C15.3041 48.9556 15.6879 48.634 15.6879 48.0853C15.6879 47.5365 15.3041 47.2073 14.7397 47.2073Z"
                fill="#B94A32"/>
              <path
                d="M20.8731 52.0323L20.4893 50.9083H18.2955L17.9155 52.0323H17.0161L19.0556 46.4429H19.733L21.7725 52.0323H20.8769H20.8731ZM19.4131 47.7068L18.5476 50.1893H20.256L19.4131 47.7068Z"
                fill="#B94A32"/>
              <path
                d="M25.87 51.5063C25.5201 51.8582 25.0121 52.0323 24.4326 52.0323H22.4834V46.4429H24.4326C25.0121 46.4429 25.5201 46.6169 25.87 46.9689C26.4721 47.5744 26.4307 48.3918 26.4307 49.1978C26.4307 50.0039 26.4683 50.9008 25.87 51.5063ZM25.2943 47.5819C25.0572 47.3208 24.7487 47.2073 24.3498 47.2073H23.3263V51.2716H24.3498C24.7487 51.2716 25.0572 51.1543 25.2943 50.897C25.5841 50.5753 25.5916 49.9698 25.5916 49.2016C25.5916 48.4334 25.5841 47.9074 25.2943 47.5857"
                fill="#B94A32"/>
              <path d="M28.4477 46.4429H27.6011V52.0323H28.4477V46.4429Z" fill="#B94A32"/>
              <path
                d="M33.2343 52.0323L30.6642 48.1004V52.0323H29.8213V46.4429H30.5927L33.1628 50.371V46.4429H34.0019V52.0323H33.2343Z"
                fill="#B94A32"/>
              <path
                d="M38.7054 51.4457C38.284 51.8923 37.7609 52.0815 37.1739 52.0815C36.5869 52.0815 36.0977 51.8696 35.7139 51.4836C35.1645 50.9348 35.1758 50.3142 35.1758 49.2395C35.1758 48.1647 35.1683 47.5441 35.7139 46.9954C36.094 46.6094 36.5794 46.3975 37.1739 46.3975C38.3555 46.3975 39.0403 47.177 39.1984 48.1345H38.3442C38.205 47.5214 37.7986 47.1619 37.1739 47.1619C36.8503 47.1619 36.5493 47.2943 36.3536 47.51C36.0827 47.809 36.0187 48.1193 36.0187 49.2433C36.0187 50.3672 36.0827 50.6888 36.3536 50.984C36.5493 51.1959 36.8465 51.3208 37.1739 51.3208C37.5427 51.3208 37.8625 51.1808 38.0808 50.9273C38.284 50.6926 38.363 50.4088 38.363 50.0493V49.7352H37.1739V49.0124H39.2059V49.8677C39.2059 50.5905 39.0742 51.0521 38.7054 51.4457Z"
                fill="#B94A32"/>
              <path
                d="M42.0471 52.0776C41.2117 52.0776 40.6097 51.8846 40.0791 51.3396L40.6398 50.7833C41.0462 51.192 41.494 51.3169 42.0622 51.3169C42.7884 51.3169 43.2099 51.0028 43.2099 50.4617C43.2099 50.2195 43.1421 50.0151 42.9916 49.8789C42.8524 49.7464 42.7132 49.6897 42.3858 49.6443L41.7273 49.5497C41.2757 49.4891 40.9182 49.3302 40.6736 49.1031C40.4027 48.8458 40.2672 48.4901 40.2672 48.0359C40.2672 47.0634 40.9709 46.3936 42.1261 46.3936C42.8599 46.3936 43.3754 46.5828 43.8533 47.0293L43.3152 47.5629C42.9728 47.2337 42.5739 47.1315 42.1073 47.1315C41.4526 47.1315 41.0913 47.5099 41.0913 48.0019C41.0913 48.2062 41.1553 48.3879 41.3021 48.5203C41.4413 48.6452 41.6671 48.7398 41.9267 48.7777L42.5589 48.8723C43.0744 48.9517 43.3641 49.0766 43.5974 49.2885C43.9022 49.5534 44.0528 49.9546 44.0528 50.4352C44.0528 51.4645 43.2136 52.0738 42.0546 52.0738"
                fill="#B94A32"/>
              <path d="M46.9804 47.2073V52.0323H46.1375V47.2073H44.606V46.4429H48.5119V47.2073H46.9804Z"
                    fill="#B94A32"/>
              <path
                d="M52.1168 52.0323L51.733 50.9083H49.5392L49.1553 52.0323H48.2598L50.2993 46.4429H50.9766L53.0124 52.0323H52.113H52.1168ZM50.6568 47.7068L49.7913 50.1893H51.5034L50.6568 47.7068Z"
                fill="#B94A32"/>
              <path
                d="M57.1328 52.0323L54.5665 48.1004V52.0323H53.7236V46.4429H54.495L57.0651 50.371V46.4429H57.908V52.0323H57.1328Z"
                fill="#B94A32"/>
              <path
                d="M62.6717 51.5063C62.3179 51.8582 61.8137 52.0323 61.2342 52.0323H59.2812V46.4429H61.2342C61.8137 46.4429 62.3179 46.6169 62.6717 46.9689C63.2737 47.5744 63.2323 48.3918 63.2323 49.1978C63.2323 50.0039 63.2737 50.9008 62.6717 51.5063ZM62.0922 47.5819C61.8589 47.3208 61.5465 47.2073 61.1477 47.2073H60.1241V51.2716H61.1477C61.5465 51.2716 61.8589 51.1543 62.0922 50.897C62.3819 50.5753 62.3894 49.9698 62.3894 49.2016C62.3894 48.4334 62.3819 47.9074 62.0922 47.5857"
                fill="#B94A32"/>
              <path
                d="M67.5147 52.0323L67.1347 50.9083H64.9409L64.5571 52.0323H63.6577L65.6935 46.4429H66.3746L68.4141 52.0323H67.5147ZM66.0547 47.7068L65.1892 50.1893H66.8976L66.0547 47.7068Z"
                fill="#B94A32"/>
              <path
                d="M72.1956 52.0323L71.0215 49.6784H69.9679V52.0323H69.125V46.4429H71.2812C72.3348 46.4429 73.0046 47.124 73.0046 48.0739C73.0046 48.8762 72.5192 49.3871 71.8983 49.5573L73.1777 52.0285H72.1956V52.0323ZM71.2172 47.2073H69.9679V48.9556H71.2172C71.7816 48.9556 72.1617 48.634 72.1617 48.0853C72.1617 47.5365 71.7816 47.2073 71.2172 47.2073Z"
                fill="#B94A32"/>
              <path
                d="M77.5506 51.5063C77.2006 51.8582 76.6926 52.0323 76.1131 52.0323H74.1602V46.4429H76.1131C76.6888 46.4429 77.1968 46.6169 77.5506 46.9689C78.1526 47.5744 78.1112 48.3918 78.1112 49.1978C78.1112 50.0039 78.1489 50.9008 77.5506 51.5063ZM76.9748 47.5819C76.7415 47.3208 76.4292 47.2073 76.0266 47.2073H75.0068V51.2716H76.0266C76.4254 51.2716 76.7378 51.1543 76.9748 50.897C77.2608 50.5753 77.2721 49.9698 77.2721 49.2016C77.2721 48.4334 77.2646 47.9074 76.9748 47.5857"
                fill="#B94A32"/>
              <path
                d="M80.76 52.0776C79.9246 52.0776 79.3226 51.8846 78.792 51.3396L79.3527 50.7833C79.7591 51.192 80.2031 51.3169 80.7751 51.3169C81.5013 51.3169 81.9228 51.0028 81.9228 50.4617C81.9228 50.2195 81.8513 50.0151 81.7045 49.8789C81.5653 49.7464 81.4223 49.6897 81.0949 49.6443L80.4402 49.5497C79.9848 49.4891 79.6274 49.3302 79.3865 49.1031C79.1118 48.8458 78.9801 48.4901 78.9801 48.0359C78.9801 47.0634 79.68 46.3936 80.8353 46.3936C81.569 46.3936 82.0846 46.5828 82.5587 47.0293L82.0206 47.5629C81.6782 47.2337 81.2793 47.1315 80.8127 47.1315C80.1579 47.1315 79.7967 47.5099 79.7967 48.0019C79.7967 48.2062 79.8607 48.3879 80.0074 48.5203C80.1466 48.6452 80.3724 48.7398 80.6321 48.7777L81.2642 48.8723C81.7798 48.9517 82.0695 49.0766 82.3028 49.2885C82.6076 49.5534 82.7544 49.9546 82.7544 50.4352C82.7544 51.4645 81.919 52.0738 80.7562 52.0738"
                fill="#B94A32"/>
              <path d="M84.6473 51.0747H83.6953V52.0321H84.6473V51.0747Z" fill="#A4167F"/>
              <path
                d="M52 24.3237C71.2324 24.3237 89.1101 28.1875 104 34.8138L52 0.081543L0 34.8138C14.8899 28.1875 32.7676 24.3237 52 24.3237Z"
                fill="#B94A32"/>
              <path
                d="M55.8646 6.70046C55.0556 4.89535 54.0659 3.28703 52.9709 1.9209C53.7235 3.01456 54.4159 4.2331 55.0067 5.55382C58.0772 12.4034 57.4338 19.3476 53.5015 21.13C50.2804 22.5907 45.6821 20.1234 42.4385 15.4119C45.6934 20.884 50.8072 23.8887 54.3143 22.2993C58.2691 20.5056 58.9352 13.55 55.8684 6.70046"
                fill="white"/>
              <path
                d="M47.0293 9.13379H47.0857C47.0405 9.69765 47.018 10.2539 46.9954 10.8102C47.1534 10.8102 47.669 10.7989 48.1882 10.7762C48.1769 10.8556 48.1769 10.9351 48.1769 11.0146C48.1769 11.0941 48.1769 11.1735 48.1882 11.253C47.7931 11.2303 47.2663 11.2303 46.9954 11.2303C46.9728 11.3438 46.9164 13.1187 46.9164 14.1064C46.9164 14.954 46.9389 15.8169 47.827 15.8169C47.9512 15.8169 48.1882 15.7828 48.335 15.7374V16.0099C48.0753 16.1007 47.748 16.1801 47.4695 16.1801C46.5363 16.1801 46.0848 15.6503 46.0848 14.7648C46.0848 13.9398 46.1525 12.9181 46.1638 11.2341C45.938 11.2341 45.6896 11.2341 45.3999 11.2568C45.4112 11.166 45.4225 11.0865 45.4225 10.9957C45.4225 10.9162 45.4112 10.8481 45.3999 10.7686C45.7386 10.7913 45.9832 10.8027 46.1412 10.8027C46.1299 10.5075 46.1186 10.0231 46.096 9.53492L47.0293 9.13757V9.13379Z"
                fill="white"/>
              <path
                d="M49.1366 14.9727C49.2946 15.2792 49.7763 15.7522 50.4085 15.7522C50.9729 15.7522 51.4997 15.4003 51.4997 14.7343C51.4997 13.3303 48.8431 14.1553 48.8431 12.2442C48.8431 11.2603 49.5166 10.5337 50.7095 10.5337C51.2626 10.5337 51.6088 10.738 51.9588 10.8516C51.8346 11.0673 51.7104 11.4835 51.6878 11.6538H51.5524C51.4395 11.2905 51.067 10.9537 50.5627 10.9537C50.1225 10.9537 49.6069 11.2376 49.6069 11.9036C49.6069 13.3757 52.2636 12.4713 52.2636 14.3823C52.2636 15.2414 51.4884 16.1723 50.2391 16.1723C49.5505 16.1723 49.1027 15.9793 48.7866 15.7976C48.8769 15.6046 49.0011 15.1997 49.0011 14.9727H49.1366Z"
                fill="white"/>
              <path
                d="M53.5577 8.10449C53.8737 8.10449 54.1334 8.36561 54.1334 8.68349C54.1334 9.00137 53.8737 9.26249 53.5577 9.26249C53.2416 9.26249 52.9819 9.00137 52.9819 8.68349C52.9819 8.36561 53.2416 8.10449 53.5577 8.10449ZM53.14 12.7667C53.14 12.0894 53.1174 11.2947 53.0497 10.6513C53.219 10.6854 53.3883 10.7081 53.5539 10.7081C53.7195 10.7081 53.8926 10.6854 54.0619 10.6513C53.9942 11.2984 53.9716 12.0894 53.9716 12.7667V13.9437C53.9716 14.621 53.9942 15.4157 54.0619 16.0591C53.8926 16.025 53.7232 16.0023 53.5539 16.0023C53.3846 16.0023 53.2152 16.025 53.0497 16.0591C53.1174 15.412 53.14 14.621 53.14 13.9437V12.7667Z"
                fill="white"/>
              <path d="M96.3313 34.7987V33.6483H95.9023V33.4932H96.9334V33.6483H96.5044V34.7987H96.3313Z"
                    fill="#B94A32"/>
              <path
                d="M97.1064 34.7986V33.4893H97.3661L97.6747 34.4164C97.701 34.5034 97.7236 34.5678 97.7349 34.6094C97.7499 34.5602 97.7725 34.4921 97.8026 34.3975L98.1149 33.4893H98.3445V34.7986H98.1789V33.705L97.8026 34.7986H97.6483L97.272 33.686V34.7986H97.1064Z"
                fill="#B94A32"/>
              <path
                d="M87.4466 52.0816C86.314 52.0816 85.4561 51.3058 85.4561 50.1516V46.4468H86.2989V50.11C86.2989 50.8593 86.7505 51.3209 87.4466 51.3209C88.1428 51.3209 88.6019 50.8593 88.6019 50.11V46.4468H89.4448V50.1516C89.4448 51.3058 88.5793 52.0816 87.4466 52.0816Z"
                fill="#B94A32"/>
              <path
                d="M94.0734 52.0324L92.5118 49.2812L91.5748 50.4089V52.0324H90.7319V46.4468H91.5748V49.2547L93.8476 46.4468H94.8787L93.0762 48.6341L95.0668 52.0324H94.0734Z"
                fill="#B94A32"/>
            </svg>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
